<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md4>
                  <v-text-field
                    v-model="search.serial"
                    :label="$t('evoucher.voucherSerial')"
                    @keydown.enter="lookupVoucherInfoList($event)"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="lookupVoucherInfoList($event)"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <!-- Receive list -->
        <material-card
          :title="$t('infoVoucher.list')"
          color="green"
          flat
          full-width
        >
          <v-data-table
            :headers="voucherInfoHeaders"
            :items="voucherInfoList"
            :no-data-text="$t('common.noDataAvailable')"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }">
              <tr
                :class="item.isLoading ? 'bg-1' : ''"
                style="position: relative;">
                <td
                  v-for="(header, index) in voucherInfoHeaders"
                  :key="header.value + index"
                >
                  <span v-if="header.value == 'action'">
                    <v-tooltip
                      v-if="useAvailable(item)"
                      top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          :loading="item['resendVoucherInfoLoading']"
                          class="mx-2"
                          fab
                          small
                          color="success"
                          style="color: #ffffff;"
                          v-on="on"
                          @click="onShowModalUsedVoucherPromotion($event, item)"
                        >
                          <v-icon dark>mdi-cart-check</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('voucherHistory.type.usePromotionVoucher') }}</span>
                    </v-tooltip>
                  </span>
                  <span v-else>{{ item[header.value] }}</span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </material-card>
        <pagination
          :total="paginate.totalPage"
          :current-page="paginate.currentPage"
          :row-per-page="paginate.rowPerPage"
          @onPageChange="onPageChange"
        />
      </v-flex>
    </v-layout>
    <loading-bar :is-loading="isLoading" />
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onConfirm"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dateUtils from 'utils/dateUtils'
import functionUtils from 'utils/functionUtils'
import EntityType from 'enum/entityType'
import moment from 'moment'
import ToastType from 'enum/toastType'
import PromotionType from 'enum/promotionType'
export default {
  data: () => ({
    voucherInfoHeaders: [
      { text: 'common.nonumber', value: 'stt' },
      { text: 'evoucher.voucherSerial', value: 'serial' },
      { text: 'evoucher.issuance.price', value: 'value' },
      { text: 'createVoucher.startDateOfUse', value: 'startDate' },
      { text: 'createVoucher.endDateOfUse', value: 'endDate' },
      { text: 'promotionVoucher.remainQuantity', value: 'remainQuantity' },
      { text: 'common.actions', value: 'action' }
    ],
    voucherInfoList: [],
    paginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    search: {
      serial: '',
      email: '',
      phone: ''
    },
    isLoading: false,
    voucherSelected: null,
    confirmModalTitle: '',
    confirmType: {
      CONFIRM_DIRECT_USED: 1,
      RESEND_VOUCHER_INFO: 2
    },
    confirmTypeSelected: null,
    promotionType: {
      ALLOCATED: PromotionType.ALLOCATED,
      NOT_ALLOCATED: PromotionType.NOT_ALLOCATED
    },
    voucherInfoHeadersClone: []
  }),
  computed: {
    ...mapGetters([
      'VOUCHER_PROMOTION_LOOKUP_DATA',
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
    VOUCHER_PROMOTION_LOOKUP_DATA () {
      let res = this.VOUCHER_PROMOTION_LOOKUP_DATA
      // Handle paginate
      this.paginate.currentPage = res.paginate.currentPage
      this.paginate.totalPage = res.paginate.totalPage
      this.paginate.rowPerPage = res.paginate.perPage / 2
      // Handle data
      let data = res.results
      this.voucherInfoList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let voucherInfoObj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          serial: data[i].serial,
          value: functionUtils.convertFormatNumberToMoney(data[i].value),
          startDate: dateUtils.formatBeautyDate(data[i].start_date),
          endDate: dateUtils.formatBeautyDate(data[i].end_date),
          id: data[i].id,
          remainQuantity: data[i].promotion_type === this.promotionType.NOT_ALLOCATED ? data[i].remain_quantity : data[i].num_of_uses_per_person
        }
        if (data[i].promotion_type === this.promotionType.ALLOCATED) {
          this.voucherInfoHeaders = this.voucherInfoHeadersClone.filter((item) => {
            return item.value !== 'remainQuantity'
          })
        } else {
          this.voucherInfoHeaders = this.voucherInfoHeadersClone
        }
        this.voucherInfoList.push(voucherInfoObj)
      }
      this.isLoading = false
    }
  },
  created () {
    this.lookupVoucherInfoList()
    this.voucherInfoHeadersClone = [...this.voucherInfoHeaders]
  },
  methods: {
    /**
     * Confirm used voucher
     */
    onConfirm: function () {
      let filter = {
        serial: this.voucherSelected.serial
      }
      this.USED_VOUCHER_PROMOTION(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.lookupVoucherInfoList()
        }.bind(this)
      ).catch(
        function (error) {
          this.isLoading = false
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              message: this.$t(`${errorText}`),
              styleType: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              message: this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    /**
     * Check button use available
     */
    useAvailable: function (voucher) {
      let startDateFormat = dateUtils.formatCompareDate(voucher.startDate)
      let endDateFormat = dateUtils.formatCompareDate(voucher.endDate)
      let currentDate = moment().format(dateUtils.STATIC_FORMAT_DATE_SQL)
      if (currentDate >= startDateFormat && currentDate <= endDateFormat && voucher.remainQuantity > 0) {
        return true
      } else {
        return false
      }
    },
    /**
     * Confirm used voucher promotion
     */
    onShowModalUsedVoucherPromotion: function (event, voucher) {
      event.preventDefault()
      this.voucherSelected = voucher
      this.confirmModalTitle = this.$t('promotionVoucher.confirmUsed')
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Check is supplier
     */
    isSupplier: function () {
      return functionUtils.isEntityType(EntityType.SUPPLIER)
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.paginate.currentPage = page
      this.lookupVoucherInfoList()
    },
    /**
     * Get voucher history list
     */
    lookupVoucherInfoList: function (event) {
      if (event) {
        event.preventDefault()
      }
      let filter = {
        params: {
          page: this.paginate.currentPage,
          serial: !functionUtils.isNull(this.search.serial) ? this.search.serial.trim() : null
        }
      }
      this.isLoading = true
      this.LOOKUP_VOUCHER_PROMOTION_INFO(filter).then(
        function () {
        }
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    ...mapActions([
      'LOOKUP_VOUCHER_PROMOTION_INFO',
      'ON_SHOW_TOAST',
      'USED_VOUCHER_PROMOTION'
    ])
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer !important;
}
.bg-1 {
  animation: myBg 5s infinite;
}
@keyframes myBg {
  from {background-color: #E8F5E9;}
  to {background-color: #4CAF50;}
}
</style>
